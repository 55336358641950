jQuery(function($) {

	/*$('button.hamburger').on("mouseover", function() {
		$(this).addClass('is-active');
	});
	$('button.hamburger').on("mouseout", function() {
		$(this).removeClass('is-active');
	});*/
	$('button.hamburger').on("click", function() {
		$(this).toggleClass('is-active');
	});

	/*$('#filter').submit(function() {

		var filter = $('#filter');
		$.ajax({
			url: filter.attr('action'),
			data: filter.serialize(), // form data
			type: filter.attr('method'), // POST
			beforeSend: function(xhr) {
				filter.find('button').text('Procesando...'); // changing the button label
			},
			success: function(data) {
				filter.find('button').text('Aplicar filtro'); // changing the button label back
				$('#vehiculos').html(data); // insert data
			}
		});
		return false;

	});*/

	// -------------------------------
	// Filer Everything - Traducciones
	// -------------------------------

	$(".wpc-posts-found p").each(function() {

		console.log($(this).text().length);

		if($(this).text().length == 1) return;

		var texto, texto_primero, texto_segundo;
		var texto_array = $(this).text().split(' ');
		var texto_segundo_array = texto_array.slice(-2);
		texto_primero = texto_array[0];
		texto_segundo = texto_segundo_array.join(' ');
		texto_segundo = traducir(texto_segundo);
		texto = texto_primero + ' ' + texto_segundo;
		$(this).text(texto);

	});

	$('#wpc-option-post_meta-marca-0').each(function() {

		$(this).text(traducir($(this).text()));

	});

	function traducir(t) {

		switch(t) {
		case 'vehículo found' :
			var t_trad = 'vehículo encontrado';
			return t_trad;
		case 'vehículos found' :
			var t_trad = 'vehículos encontrados';
			return t_trad;
		case '- Select Marca -' :
			var t_trad = '- Seleccionar Marca -';
			return t_trad;
		}

	}

});